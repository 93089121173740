<template>
	<span>
		<v-btn v-if="back" icon :disabled="isFirst" @click="previous"><v-icon>mdi-arrow-left-drop-circle</v-icon></v-btn>
		<v-btn v-else icon :disabled="isLast" @click="next"><v-icon>mdi-arrow-right-drop-circle</v-icon></v-btn>		
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "DirectionButton", 
		props: {
			back: {type: Boolean},
			forward: {type: Boolean, default: true}, 
			array: {type: Array},
			value: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			currentPosition(){
				return this.array.indexOf( this.value );
			},
			isFirst(){
				return this.currentPosition == 0;
			},
			isLast(){
				return this.currentPosition == (this.array.length - 1)
			}
		},
		methods: {
			previous(){
				var prev = this.array[this.currentPosition - 1];
				this.$emit("click", prev)
			},
			next(){
				var next = this.array[this.currentPosition + 1];
				this.$emit("click", next)
			}
		}
		// created(){
			
		// }

	}
// </script>"
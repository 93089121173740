<template>
	<v-sheet flat>
		<v-card flat color="secondary" v-if="!haveConfig">
			<v-card-text>	
				Blurb 
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" to="/assessment-config/departments">{{mwtr.DepartmentConfig}}</v-btn>
			</v-card-actions>
		</v-card>
		<template v-else>
			<v-card flat v-if="!currentSection">
				<v-card-text>Hier können Sie die Voreinstellung der Digitalisierungsziele individuell auf Ihre Bereiche anpassen und Ziele für jeden Bereich abwählen oder weitere Ziele hinzufügen. </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="start(firstDepartment)">{{mwtr.SectionConfig}}</v-btn>
				</v-card-actions>
			</v-card>

			<v-card v-else>
				<SectionConfigToolbar title
				:text="currentSection.name" 
				:value="currentSection.department" 
				:array="orderedDepartments" 
				@click="(d) => start(d)">
					<template v-slot:badge>
						<StatusChip class="ml-3" :department="currentSection.department"></StatusChip>
					</template>
				</SectionConfigToolbar>			
				<PartConfig ></PartConfig>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="save">{{mwtr.SaveButton}}</v-btn>
				</v-card-actions>
			</v-card>
			<Saved :trigger="saved" @close="saved = false"></Saved>
		</template>
	</v-sheet>

</template>

<style lang="less">
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import SectionConfigToolbar from "@/components/user/assessment-config/SectionConfigToolbar"
	import PartConfig from "@/components/user/assessment-config/PartConfig"
	import StatusChip from "@/components/navigation/StatusChip"
	export default {
		name: "SectionConfig", 
		props: {
		},
		data: () => {
			return {
				saved: false
			}
		},
		components: {
			SectionConfigToolbar,
			PartConfig,
			Saved, 
			StatusChip
		},
		computed: {
			...Vuex.mapState({
				sections: state => state.sections,
				departments: state => state.departments,
				config: state => state.assessment.config
			}),
			haveConfig(){
				var self = this;
				if( !self.config || Object.keys(self.config.sections).length == 0 ){
					return false;
				}

				return true;
			},
			map(){
				return this.$store.getters.igMapped;
			},
			orderedDepartments(){
				return this.$store.getters.orderOfDepartments;
			},
			firstDepartment(){
				return this.orderedDepartments[0];
			},
			currentSection(){
				return this.$store.getters.currentSection
			},	

		},
		methods: {
			start(department){
				if( !this.haveConfig ){
					return false;
				}
				this.$store.dispatch("assessment/saveConfig", this.config)
				var part = this.map[department].partOrder[0];
				this.$store.commit("GO_TO", {part, department})
			}, 
			save(){
				var self = this;
				self.$store.dispatch("assessment/saveConfig", self.config ).then(() => {
					self.saved = true;
				})
			}
		},

	}
// </script>"

<template>
	<p>Wählen Sie hier Ihre zu bewertenden Fachabteilungen aus, die in Ihre Gesamtstrategie einbezogen werden sollen</p>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "SectionConfigIntro", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"
<template>
	<v-list-item class="clickable" dense :class="classes">
		<v-list-item-icon>			
			<v-btn icon  @click="toggleGoal">
				<v-icon v-if="selected" color="green">mdi-checkbox-marked-outline</v-icon>
				<v-icon v-if="!selected" color="grey">mdi-checkbox-blank-outline</v-icon>
			</v-btn>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title class="pr-4">	
				{{goal.name}} 
			</v-list-item-title>
		</v-list-item-content>
				<v-badge color="green" class="white--text mr-4" :content="goalFrequency"></v-badge>
		<v-list-item-action>
			<ConfigGoalMultiple v-if="!custom" :id="id"></ConfigGoalMultiple>
		</v-list-item-action>
	</v-list-item>
</template>

<style lang="less">
	@import "./../../../assets/css/variables";
	.clickable {
		cursor: pointer;
		margin-bottom: 3px;
		border: 1px solid @secondary;
	}
	.selected {
		border-left: 8px solid @ig-green;
	}
	.deselected {
		border-left: 5px solid @secondary;
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ConfigGoalMultiple from "@/components/user/assessment-config/ConfigGoalMultiple"
	export default {
		name: "GoalConfig", 
		props: {
			id: {}
		},
		data: () => {
			return {
			}
		},
		components: {
			ConfigGoalMultiple
		},
		computed: {
			...Vuex.mapState({
				goal( state ){
					var id = this.id;
					return state.goals[id]
				},
				sections: state => state.sections,
				part: state => state.currentPart
			}),
			currentSection(){
				return this.$store.getters.currentSection
			},
			disabled(){
				var status = this.currentSection.status;
				return ["delegated", "complete"].includes(status)
			},
			map(){
				return this.$store.getters.igMappedFlat;
			},
			selected(){
				var selected = false;
				var uniqueId = `${this.currentSection.department}-${this.part}-${this.id}`;
				var included = this.map.find( a => a.uniqueId == uniqueId );
				if( included ){
					selected = true
				}
				return selected;				
			},
			classes(){
				return {
					selected: this.selected,
					deselected: !this.selected
				}
			},
			goalFrequency(){
				var id = this.id; 
				return this.map.filter( m => m.goal == id ).length;
			},
			custom(){
				var id = this.id;
				if( !this.sections[this.currentSection.section] || !this.sections[this.currentSection.section].parts[this.part] ){
					return true;
				}
				return !this.sections[this.currentSection.section].parts[this.part].goals.includes(id)
			}
		},
		methods: {
			toggleGoal(){
				if( this.disabled ){
					this.$emit("error");
					return;
				}
				if( this.selected ){
					this.$store.dispatch("assessment/removeGoalFromConfig", {
						goal: this.id, 
						department: this.currentSection.department, 
						part: this.part
					})
				}
				else{
					this.$store.dispatch("assessment/addGoalToConfig", {
						goal: this.id, 
						department: this.currentSection.department, 
						part: this.part
					})

				}
			}
		}
		// created(){
			
		// }

	}
// </script>"
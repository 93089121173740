<template>
	<div>
		<SectionConfigToolbar 
			:text="partName" 
			:value="part" 
			:array="defaultPartOrder" 
			@click="(d) => changePart(d)">
				
		</SectionConfigToolbar>	
		<v-card-text>
			<v-list>
				<template v-for="goal in goals">					
					<GoalConfig @error="error = true" :key="goal.goal" :id="goal.goal"></GoalConfig>
				</template>
			</v-list>
			<p>
				<v-btn :disabled="disabled" small text color="ig-black" @click="toggleAll">{{hasAll ? mwtr.DeselectAll : mwtr.SelectAll}}</v-btn>
				<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
					<template v-slot:activator="{on}">
						<v-btn :disabled="disabled" small text color="ig-black" v-on="on">{{mwtr.NewGoalNavItem}}</v-btn>
					</template>
					<NewGoal :exclude="goals.map( g => g.goal)" @new="newGoal"></NewGoal>
				</v-dialog>
			</p>
		</v-card-text>
		<ErrorMessage v-if="disabled" :trigger="error" @close="error = false" :message="errorMessage"></ErrorMessage>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import SectionConfigToolbar from "@/components/user/assessment-config/SectionConfigToolbar"
	import GoalConfig from "@/components/user/assessment-config/GoalConfig"
	import NewGoal from "@/components/user/assessment-config/NewGoal"
	import ErrorMessage from "@/components/notices/ErrorMessage"
	export default {
		name: "PartConfig", 
		props: {
			
		},
		data: () => {
			return {
				error: false,
				dialog: false
			}
		},
		components: {
			SectionConfigToolbar,
			GoalConfig, 
			ErrorMessage,
			NewGoal
		},
		computed: {
			...Vuex.mapState({
				part: state => state.currentPart,
				goalGroups: state => state.goalGroups,
				section(state){
					var id = this.currentSection.section;
					return state.sections[id]
				},
				sections: state => state.sections,
				config: state => state.assessment.config
			}),
			currentSection(){
				return this.$store.getters.currentSection
			},
			disabled(){
				var status = this.currentSection.status;
				return ["delegated", "complete"].includes(status)
			},
			errorMessage(){
				if( this.currentSection.status == "delegated" ){
					return this.mwtr.DelegationWarning
				}				
				if( this.currentSection.status == "complete" ){
					return this.mwtr.CompleteWarning
				}				
				return false;			
			},
			defaultPartOrder(){
				return this.$store.getters.defaultPartOrder
			},
			partName(){
				var part = this.section.parts[this.part];
				var goalGroup = this.goalGroups[part.goalGroup];
				return goalGroup ? goalGroup.name : "";
			},
			configuredGoals(){
				const self = this;
				return self.$store.getters.igMappedFlat.filter( p => p.part == self.part );
			},
			selectedGoals(){
				return this.currentSection.parts[this.part] ? this.currentSection.parts[this.part].goals : [];
			},
			defaultGoals(){
				var part = this.part;
				return this.$store.getters.defaultGoalsFlat.filter( b => b.part == part )
			},
			customGoals(){
				const self = this;
				return self.configuredGoals.filter( g => !self.defaultGoals.find( b => g.goal == b.goal ) )
			},
			goals(){
				const self = this;
				var goals = self.defaultGoals;
				goals = goals.filter( goal => goal.part == self.part  );
				goals = goals.concat( self.customGoals )
				return goals;
			},
			hasAll(){
				var self = this;
				var defaultGoals = self.goals.map( g => g.goal );
				var diff = defaultGoals.filter( d => !this.selectedGoals.includes(d) );
				return diff.length == 0; 
			}
		},
		methods: {
			changePart(part){
				this.$store.dispatch("assessment/saveConfig", this.config)
				this.$store.commit("GO_TO", {part})
			}, 
			toggleAll(){
				const self = this;
				if( self.hasAll ){
					self.selectedGoals.forEach( g => {
						self.$store.dispatch("assessment/removeGoalFromConfig", {
							goal: g, 
							department: self.currentSection.department, 
							part: self.part
						})
					})
				}
				else{
					// addAll
					var defaultGoals = self.goals.map( g => g.goal );
					defaultGoals.forEach( g => {
						if( !self.selectedGoals.includes(g) ){
							self.$store.dispatch("assessment/addGoalToConfig", {
								goal: g, 
								department: self.currentSection.department, 
								part: self.part
							})
						}
					})
				}
			},
			newGoal(id){
				const self = this;
				self.$store.dispatch("assessment/addGoalToConfig", {
					goal: id, 
					department: self.currentSection.department, 
					part: self.part
				}).then(( ) => {
					self.dialog = false;
				})
			}
		}
		// created(){
			
		// }

	}
// </script>"
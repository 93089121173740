<template>
	<v-toolbar flat>
			<DirectionButton back :array="array" :value="value" @click="(d) => $emit('click', d)"></DirectionButton>
			<span :class="{title: title}">{{text}}</span>
			<slot name="badge"></slot>
				<v-spacer></v-spacer>
		<DirectionButton forward :array="array" :value="value" @click="(d) => $emit('click', d)"></DirectionButton>
	</v-toolbar>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import DirectionButton from "@/components/elements/DirectionButton"
	export default {
		name: "SectionConfigToolbar", 
		props: {
			text: {},
			title: {type: Boolean},
			array: {},
			value: {}
		},
		data: () => {
			return {

			}
		},
		components: {
			DirectionButton
		},
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"
<template>
	<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
		<template v-slot:activator="{on}">			
			<v-btn icon v-on="on">
				<v-icon color="ig-black">mdi-format-list-checks</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<v-list>
					<v-list-item>
						<v-list-item-content></v-list-item-content>
						<v-list-item-action>							
							<v-checkbox @change="selectAll" :indeterminate="indeterminate"></v-checkbox>
						</v-list-item-action>						
					</v-list-item>
					<v-list-item v-for="goal in defaultGoals" :key="goal.uniqueId">
						<v-list-item-content>
							<p><b>{{mwtr.DepartmentNavItem}}: {{map[goal.department].name}}</b> 
							{{mwtr.GoalGroup}}: {{partNames[goal.part]}}</p>	
						</v-list-item-content>
						<v-list-item-action>							
							<v-checkbox :disabled="disabled(goal.department)"  :value="goal.uniqueId" v-model="selected">
							</v-checkbox>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="save" color="primary">{{mwtr.SaveButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "ConfigGoalMultiple", 
		props: {
			id: {}	
		},
		data: () => {
			return {
				dialog: false,
				selected: [],
			}
		},
		// components: {

		// },
		computed: {
			partNames(){
				return this.$store.getters.partNames;
			},
			map(){
				return this.$store.getters.igMapped;
			},
			flat(){
				return this.$store.getters.igMappedFlat;
			},
			defaultGoals(){
				var id = this.id;
				return this.$store.getters.defaultGoalsFlat.filter( a => a.goal == id);
			},
			goals(){
				var id = this.id;
				var configured = this.flat.filter( a => a.goal == id);
				return configured;
			}, 
			indeterminate(){
				return this.selected.length > 0 && this.selected.length < this.goals.length;
			},
			deselected(){
				var selected = this.selected;
				return this.goals.map( g => g.uniqueId ).filter( g => !selected.includes(g) )
			}
		},
		methods: {
			selectAll(e){
				var self = this;
				var canNotChange = self.goals.filter( a => self.disabled(a.department )).filter( a => self.selected.includes(a.uniqueId) ).map( a => a.uniqueId );
				if( e ){
					self.selected = canNotChange.concat( self.defaultGoals.filter( g => !self.disabled(g.department) ).map( g => g.uniqueId ) )
				}
				else{
					self.selected = canNotChange;
				}
			},
			disabled(id){
				if( !this.map || !this.map[id] ){
					return false;
				}
				return ["delegated", "complete"].includes(this.map[id].status) 
			},
			save(){
				var self = this;
				self.selected.forEach( uid => {
					if( !self.goals.find( a => a.uniqueId == uid ) ) {
						var goal = self.defaultGoals.find( a => a.uniqueId == uid );
						self.$store.dispatch("assessment/addGoalToConfig", {
							goal: goal.goal, 
							department: goal.department, 
							part: goal.part
						})
					}
				})
				self.deselected.forEach( uid => {
					var goal = self.goals.find( a => a.uniqueId == uid );
					self.$store.dispatch("assessment/removeGoalFromConfig", {
						goal: goal.goal, 
						department: goal.department, 
						part: goal.part
					})
				});
				this.dialog = false;
			}

		},
		watch: {
			goals: {
				immediate: true, 
				handler(){
				}
			}
		},
		created(){
			// this.selected = this
			this.selected = this.goals.map( g => g.uniqueId )	
		}

	}
// </script>"
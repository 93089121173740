<template>
	<v-card flat>
		<DepartmentConfigIntro></DepartmentConfigIntro>
		<v-list dense>
			<v-list-item v-for="(department, id) in departments" :key="id"  @click="toggleDepartment(id)">
				<v-list-item-content>
					<v-list-item-title>
						{{department.name}}
					</v-list-item-title>
					<v-list-item-subtitle>
						{{department.type}}
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-icon>
					<v-icon large :color="color(id)">
						{{icon(id)}}
					</v-icon>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="save" color="primary">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
		<ErrorMessage :trigger="error" :message="mwtr.CanNotReconfigureSection" @close="error = false"></ErrorMessage>
		<Saved :trigger="saved" @close="saved = false"></Saved>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vue from "vue";	
	import Vuex from "vuex";
	// import NewDepartmentInput from "@/components/user/assessment-config/NewDepartmentInput"
	import ErrorMessage from "@/components/notices/ErrorMessage"
	import Saved from "@/components/notices/Saved"
	import DepartmentConfigIntro from "@/components/user/content/DepartmentConfigIntro"
	export default {
		name: "DepartmentSelection", 
		props: {
		},
		data: () => {
			return {
				error: false,
				selected: [], 
				saved: false
			}
		},
		components: {
			Saved,
			ErrorMessage,
			DepartmentConfigIntro
			// NewDepartmentInput
		},
		computed: {
			...Vuex.mapState({
				sections: state => state.sections,
				departments: state => state.departments, 
				organisation: state => state.organisation,
				config: state => state.assessment.config,
			}), 	
			map(){
				return this.$store.getters.igMapped
			}, 
			selectedSections(){
				var self = this;
				return self.selected.filter( d => self.departments[d] ).map( d => self.departments[d].section  );
			},		
		},
		methods: {

			getDefaultConfig(department){
				return new Promise( (resolve) => {
					var section = this.departments[department].section;
					return this.$store.dispatch("sections/getDefaultConfig", section ).then((obj) => {
						resolve(obj)
					});
				})
			},

			save(){
				var self = this;
				var config = this.config.sections || {};
				Object.keys(config).forEach( key  => {
					if( !self.selected.includes(key) ){
						delete config[key]
					}
				})

				var defaults = self.selected.filter( id => !config[id] ).map( id => {
					return self.getDefaultConfig(id).then(res => {
						config[id] = res;
					})
				})
				Promise.all(defaults).then(() => 
					self.$store.dispatch("assessment/saveDepartmentConfig", config ).then(() => {
						self.saved = true;
						self.$router.push("/assessment-config/sections")
					})
				);
			},
			disabled(id){
				if( !this.map || !this.map[id] ){
					return false;
				}
				return ["delegated", "complete"].includes(this.map[id].status) 
			},
			color(id){
				if( this.disabled(id) ){
					return "grey";
				}
				if( this.selected.indexOf(id) > -1 ){
					return "green"
				}
				return "grey"
			},			
			icon(id){
				if( this.selected.indexOf(id) > -1 ){
					return "mdi-check-circle"
				}
				return "mdi-close-circle"
			},
			avatar(department){
				return department.name.charAt(0)
			},
			toggleDepartment(id){
				if( this.disabled(id) ){
					this.error = true;
					return;
				}
				if( this.selected.indexOf(id) < 0 ){
					this.selected.push( id );
				}
				else{
					this.selected = this.selected.filter(a => a !== id)
				}
			},
		},
		watch: {
			selectedSections: {
				immediate: true, 
				handler(nv, ov ){
					const self = this;
					if( !nv ){
						return;
					}
					var diff = nv;
					if( ov) {
						diff = diff.filter( v => !ov.includes(v) );
					}
					self.$store.dispatch("assessment/updateAssessmentSections", diff )
				}

			},
			organisation: {
				deep: true, 
				immediate: true, 
				handler(){
					if( !this.config ){
						if( this.organisation.departments ){
							this.selected = this.organisation.departments;
						} 
					}
				}
			},
			config: {
				immediate: true,
				handler(){
					if( this.config ){
						var departmentKeys = Object.keys( this.config.sections )
						Vue.set(this, "selected", departmentKeys )
						this.departmentConfig = this.config.sections;
					}
				}
			}
		},

	}
// </script>"